import React from "react";
import ThankYouPage from "../../components/thank-you/thank-you";
import './thank-you.scss';


export default ({location}) => {

    return (<ThankYouPage location={location}>
      <section id="main" className="main-title-section main-title-section-dark">
        <div className="page-content container">
        <div className="row items-center">
        <div className="col-12 col-md-10">
          <h1>Thank you!</h1>
          <h2 className="subtitle">We’ll be in touch soon.</h2>
          </div>
            <div className="page_icon_wrapper hidden lg:block col-2">
             <div className="page_icon"><img src="https://amazeeio.cdn.prismic.io/amazeeio/cdd4fb59-26a5-4429-bf15-90cd108b0951_Thankyou-landing.svg" alt="Thank you" /></div>
            </div>
          </div>
        </div>
      </section>
      <div className="page-content container lower-content">
        <h2>Want to learn more?</h2>
        <strong>Visit our blog:</strong>
        <ul>
          <li><a href="https://www.amazee.io/blog/post/what-is-containerized-hosting">What is containerized hosting?</a></li>
          <li><a href="https://www.amazee.io/blog/post/top-benefits-of-cicd-in-web-hosting">Top benefits of CI/CD in web hosting - and what it means for developers</a></li>
          <li><a href="https://www.amazee.io/blog/post/k8s-101-is-kubernetes-essential-for-developers">K8s 101: Is Kubernetes essential for developers? Here’s how to start using it today</a></li>
        </ul>
      </div>
      </ThankYouPage>
    );
}
